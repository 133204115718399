import { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PuffMeetoor, PropaMeetoor } from '../../../../main/loadshap/loadshap';
import ScrollBar from '../../../../main/scrollbar';
import Actions from "../../../../reducer/actions";
import Axios from '../../../../main/Axios';
import { JobSvg, SecureSvg, AskFriendSvg, FollowersSvg, CloseSvg, DeleteSvg } from '../../icons/icons';
///////////////////////////////////////////////////////
const BlockOuter = ({ name, username, photo, infos, userid, setHold,
    hold, isOwner, isSecure, followState, isfriend = false }) => {
    const { buttons, badges, messages } = useSelector(state => state.sign.langData);
    const mainSocket = useSelector(state => state.main.socket);
    const dispatch = useDispatch();
    ///////////////////////////////////////////////////////
    const addFollow = () => {
        setHold(false);
        mainSocket.emit("onFollow", { username });
        dispatch(Actions.type("setSuggestFollowers", {
            type: 'update', data: {
                target: userid,
                key: 'userid',
                callback: (target) => {
                    target.followState = <span className="propa">
                        <PropaMeetoor size={5} loading={true} clr="lit" />
                    </span>
                }
            }
        }));
    }
    ///////////////////////////////////////////////////////
    const addFriend = () => {
        setHold(false);
        mainSocket.emit("friendRequest", { username });
        dispatch(Actions.type("setSuggestFollowers", {
            type: 'update', data: {
                target: userid,
                key: 'userid',
                callback: (target) => {
                    target.isfriend = "propa";
                }
            }
        }));
    }
    ///////////////////////////////////////////////////////
    return (<div className="block-outer friends">
        <div className="user">
            <Link className="link" to={`/home/profile/?p=${username}`}>
                <div className={`user-img ${isOwner ? "owner" : ""}`}>
                    {isOwner ? <img className="owner-badge" src={process.env.PUBLIC_URL + "/img/owner.png"} alt="owner" /> : null}
                    <img className="img" src={photo} alt="photo" />
                </div>
                <div className="user-info">
                    <h1 dir="auto">
                        {name}
                    </h1>

                    {isSecure ? <div className="outer-state secure">
                        <SecureSvg className="lit" size={18} />
                        <h1 dir="auto">{messages.verifiedAcc}</h1>
                    </div> : null}
                </div>
            </Link>
        </div>
        <div className="outer-info">
            <div className="info">
                <JobSvg className="lit" size={18} />
                <span>{infos.job}</span>
            </div>
            <div className="info">
                <FollowersSvg className="lit" size={22} />
                <span>{infos?.followers + " " + badges.followers}</span>
            </div>
        </div>
        <div className="outer-tabs">
            <button className="tab grn" disabled={hold} onClick={addFollow}>
                {followState ? followState : <>
                    <AskFriendSvg className="lit" />
                    {buttons.follow}
                </>}
            </button>
            <button className="tab" disabled={hold} onClick={addFriend}>
                {isfriend === "sent" ? <>
                    <CloseSvg className="red" />
                    {buttons.cancel}
                </> : isfriend === false ? <>
                    <AskFriendSvg className="drk" />
                    {buttons.addFriend}
                </> : isfriend === "propa" ? <span className="propa">
                    <PropaMeetoor size={5} loading={true} clr="drk" />
                </span> : isfriend === true ? <>
                    <DeleteSvg className="red" />
                    {buttons.delete}
                </> : null}
            </button>
        </div>
    </div>)
}
////////////////////////////////////////////
const FollowingSuggest = memo(() => {
    const { messages, buttons, badges } = useSelector(state => state.sign.langData);
    // const mainSocket = useSelector(state => state.main.socket);
    const token = useSelector(state => state.sign.token);
    const socketLive = useSelector(state => state.socket.live);
    const User = useSelector(state => state.main.user);
    const When = useSelector(state => state.main.when);
    const data = useSelector(state => state.main.suggestFollowers);
    const dispatch = useDispatch();
    ////////////////////////////////////////
    const [hold, setHold] = useState(false);
    const [Scroll, setScroll] = useState(null);
    const [currenGet, setCurrenGet] = useState("myContacts/");
    ////////////////////////////////////////
    const getFollowerApi = async () => {
        try {
            const response = await Axios.get(currenGet, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });
            if (response.data.error) {
                dispatch(Actions.type("setSuggestFollowers", {
                    type: 'set',
                    data: []
                }));
            } else {
                dispatch(Actions.type("setSuggestFollowers", {
                    type: 'set',
                    data: response.data.sort(() => Math.random() - 0.5)
                }));
                Scroll && Scroll?.goTopSmooth();
            }
        } catch (e) {
            console.log("Dashbord -> error.data", e)
        }
    }
    ////////////////////////////////////////
    useEffect(() => {
        const { event, data, when } = socketLive;
        if (when >= When()) switch (event) {
            case "replayFriendRequest":
                console.log("🚀 replayFriendRequest", data)
                setHold(false);
                dispatch(Actions.type("setSuggestFollowers", {
                    type: 'update', data: {
                        target: data.userid,
                        key: 'userid',
                        callback: (target) => {
                            target.isfriend = "sent";
                        }
                    }
                }));
                break;

            case "cancelFriendRequest":
                console.log("cancelFriendRequest", data)

                setHold(false);
                dispatch(Actions.type("setSuggestFollowers", {
                    type: 'update', data: {
                        target: data.userid,
                        key: 'userid',
                        callback: (target) => {
                            target.isfriend = false
                        }
                    }
                }));
                break;

            case "onFollow":
                setHold(false);
                if (data.username === User.username) {
                    dispatch(Actions.type("setSuggestFollowers", {
                        type: 'update', data: {
                            target: data.another,
                            key: 'username',
                            callback: (target) => {
                                console.log("useEffect ~ target", target)

                                let followers = target.infos.followers;
                                if (data.type) {
                                    ++followers
                                    target.followState = <>
                                        <FollowersSvg className="lit" /> {buttons.following}
                                    </>
                                    target.infos.followers = followers;

                                }
                                else {
                                    --followers
                                    target.followState = undefined;
                                    target.infos.followers = followers;
                                }
                            }
                        }
                    }));
                }
                break;
        }
    }, [socketLive]);
    ////////////////////////////////////////
    useEffect(() => {
        getFollowerApi();
    }, [currenGet]);
    ////////////////////////////////////////
    return (<div className="branches-container popup">
        <div className="top-head-popup">
            <div className="info">{messages.followPopup.one}</div>
            <div className="info">{messages.followPopup.two}</div>
            <div className="redir">
                <button className={`btn-meetoor ${"myContacts/" === currenGet ? "active" : ""}`}
                    onClick={() => setCurrenGet("myContacts/")}>{badges.yourContacts}</button>
                <button className={`btn-meetoor ${"suggestFollow/" === currenGet ? "active" : ""}`}
                    onClick={() => setCurrenGet("suggestFollow/")}>{badges.peopleSuggest}</button>
            </div>
        </div>
        <ScrollBar detect={true}
            scrollRef={setScroll}>
            {(data === undefined) ?
                <PuffMeetoor size={100} loading={true} clr="drk" rev={true} />
                : data.length > 0 ? <>
                    {data.map((follow, i) => {
                        return (<BlockOuter
                            key={follow.username + i}
                            photo={follow.userPhoto}
                            name={follow.fullName}
                            userid={follow.userid}
                            infos={follow.infos}
                            isOwner={follow.isOwner}
                            isSecure={follow.isSecure}
                            setHold={setHold}
                            hold={hold}
                            isfriend={follow.isfriend}
                            followState={follow.followState}
                            username={follow.username} />)
                    })}
                </> :
                    <div className="noData-yet dark" style={{ "height": "350px" }}>
                        <div className="nodata">
                            <span>{messages.noFriends}</span>
                        </div>
                    </div>}
        </ScrollBar>
        <div className="top-head-popup bottom">
            <button className="buttons update"
                onClick={getFollowerApi}>
                {badges.update}
            </button>
            <button className="buttons" onClick={() => {
                dispatch(Actions.type("setPopupMain", false));
            }}>
                {buttons.continue}
            </button>
        </div>
    </div >)
})

export default FollowingSuggest;