import Action from "../actions";
import Time from "../../main/createtime";
import {
  setMessages,
  updateForVideo,
  createServer,
  setNotifyNumber,
  setTitle,
  setUser,
  handelSuggest,
  reobject,
} from "../helper";
//npx browserslist@latest --update-db
//rm -rf node_modules && npm install && rm -rf /tmp/metro-* && npm run start --reset-cache
/////////////////////////////////////////
const handlePopup = (action, name, newState) => {
  if (action.peyload) newState.store.popup[name] = name;
  else delete newState.store.popup[name];
};
const genrateRandom = Math.random().toString(36).substr(2, 9);
export const userInit = {
  Address: "",
  JoinedDate: "",
  isApp: false,
  Phone: "",
  UserPhoto: "https://cdn.meetoor.com/media/media/DefaultPhotos/defult.png",
  email: "meetoor@gmail.com",
  first_name: "anony",
  fullName: `meetoor_user_${genrateRandom}`,
  last_name: "user",
  status: true,
  userid: `id_${genrateRandom}`,
  username: `user_${genrateRandom}`,
};
const version = "1.8.6";
////////////////////////////////////////
const initState = {
  store: {
    popup: {},
  },
  lives: [],
  title: "Meetoor",
  time: Time,
  scrollsPosition: {},
  chatsRoom: [],
  when: () => {
    let time = new Date();
    return parseInt(
      `${time.getHours()}${time.getMinutes()}${time.getSeconds()}`
    );
  },
  suggestFriends: undefined,
  requestFriends: undefined,
  requestTeams: undefined,
  suggestTeams: undefined,
  scheduledRooms: undefined,
  receivedFriends: undefined,
  receivedTeams: undefined,
  myShareScreen: null,
  socket: null,
  toggleLeft: false,
  messages: [],
  chatUsers: undefined,
  messageNumber: 0,
  enableEmoji: false,
  allVideos: [],
  currentRooms: undefined,
  version,
  info: {
    href: "/home/profile/?p=devi",
    name: "Dev Ahmad Hassan",
    head: "meetoor developer",
    ver: `version: ${version}`,
  },
  runCall: false,
  resCall: false,
  rtcBusy: false,
  apiUrl: "https://meetoor.com",
  cdnUrl: "https://cdn.meetoor.com",
  message: "",
  GoToTop: 27,
  user: userInit,
  teamsUser: undefined,
  teamsJoin: undefined,
  friendsUser: undefined,
  anonyUsers: undefined,
  stickers: undefined,
  filter: false,
  renderLeft: true,
  popupControls: false,
  toggelMemberRoom: false,
  toggelWinRoom: false,
  popupFloatWindow: false,
  popupFloatWindowTeam: false,
  itemTeamHumber: false,
  toggleSearch: false,
  itemMainHumber: false,
  popupUserWindow: false,
  popupChatWindow: false,
  popupNotifyWindow: false,
  popupMainWindow: false,
  popupImageWindow: false,
  popupCloseWindow: false,
  // setToggelWinRoom: false,
  miniPopup: false,
  openMoreWin: false,
  replyWin: false,
  suggestFollowers: undefined,
  callcenter: undefined,
  followings: undefined,
  followers: undefined,
  myStory: undefined,
  currentSeener: undefined,
  windowStatus: false,
  currentStories: [],
  audioRoom: null,
  servers: createServer("name:run"),
};
////////////////////////////////////////
const reducer = (state = initState, action) => {
  const newState = state;
  switch (action.type) {
    /////////////////////////////////////
    case Action.setScrollsPosition:
      return {
        ...newState,
        scrollsPosition: {
          ...newState.scrollsPosition,
          [action.peyload.key]: action.peyload.value,
        },
      };
    /////////////////////////////////////
    case Action.setAudioRoom:
      return {
        ...newState,
        audioRoom: action.peyload,
      };
    /////////////////////////////////////
    case Action.setStickers:
      return {
        ...newState,
        stickers: action.peyload,
      };
    /////////////////////////////////////
    case Action.setGoToTop:
      return {
        ...newState,
        GoToTop: action.peyload,
      };
    /////////////////////////////////////
    case Action.rtcBusy:
      return {
        ...newState,
        rtcBusy: action.peyload,
      };
    /////////////////////////////////////
    case Action.setServers:
      return {
        ...newState,
        servers: createServer(action.peyload),
      };
    /////////////////////////////////////
    case Action.setRunCall:
      return {
        ...newState,
        runCall: action.peyload,
        // rtc: action.peyload.rtc || newState.rtc
      };
    /////////////////////////////////////
    case Action.setResCall:
      return {
        ...newState,
        resCall: action.peyload.run || false,
        rtc: action.peyload.rtc || newState.rtc,
      };
    /////////////////////////////////////
    case Action.setChatsRoom:
      return {
        ...newState,
        chatsRoom: setMessages(
          action.peyload.type,
          action.peyload.data,
          newState.chatsRoom
        ), //[...newState.chatsRoom, action.peyload]
      };
    /////////////////////////////////////
    case Action.setAnonyUsers:
      return {
        ...newState,
        anonyUsers: reobject(
          action.peyload.type,
          action.peyload.data,
          newState.anonyUsers
        ),
        // anonyUsers: { ...newState.anonyUsers, [action.peyload.key]: action.peyload.val }
      };
    /////////////////////////////////////
    case Action.setAllVideos:
      return {
        ...newState,
        allVideos: updateForVideo(
          action.peyload.type,
          action.peyload.data,
          action.peyload.callback,
          newState.allVideos
        ),
      };
    /////////////////////////////////////
    case Action.setMyShareScreen:
      return {
        ...newState,
        myShareScreen: action.peyload,
      };
    case Action.setFilter: //
      return {
        ...newState,
        filter: action.peyload,
        message: !action.peyload ? "" : newState.message,
      };
    /////////////////////////////////////
    case Action.setRenderLeft:
      return {
        ...newState,
        renderLeft: action.peyload,
      };
    /////////////////////////////////////
    case Action.setWindowStatus:
      handlePopup(action, "setWindowStatus", newState);
      return {
        ...newState,
        windowStatus: action.peyload,
      };
    /////////////////////////////////////
    case Action.setPopupControls:
      handlePopup(action, "setPopupControls", newState);
      return {
        ...newState,
        popupControls: action.peyload,
      };
    /////////////////////////////////////
    case Action.setToggelMemberRoom:
      handlePopup(action, "setToggelMemberRoom", newState);
      return {
        ...newState,
        toggelMemberRoom: action.peyload,
      };
    /////////////////////////////////////
    case Action.setToggelWinRoom:
      handlePopup(action, "setToggelWinRoom", newState);
      return {
        ...newState,
        toggelWinRoom: action.peyload,
      };
    /////////////////////////////////////
    case Action.setEnableEmoji:
      handlePopup(action, "setEnableEmoji", newState);
      return {
        ...newState,
        enableEmoji: action.peyload,
      };
    /////////////////////////////////////
    case Action.setOpenMoreWin:
      handlePopup(action, "setOpenMoreWin", newState);
      return {
        ...newState,
        openMoreWin: action.peyload,
      };
    /////////////////////////////////////
    case Action.setReplyWin:
      return {
        ...newState,
        replyWin: action.peyload,
      };
    /////////////////////////////////////
    case Action.setToggleSearch:
      handlePopup(action, "setToggleSearch", newState);
      return {
        ...newState,
        toggleSearch: action.peyload,
      };
    /////////////////////////////////////
    case Action.setPopupFloatWindow:
      handlePopup(action, "setPopupFloatWindow", newState);
      return {
        ...newState,
        popupFloatWindow: action.peyload,
      };
    /////////////////////////////////////
    case Action.setPopupFloatWindowTeam:
      handlePopup(action, "setPopupFloatWindowTeam", newState);
      return {
        ...newState,
        popupFloatWindowTeam: action.peyload,
      };
    /////////////////////////////////////
    case Action.setItemTeamHumber:
      handlePopup(action, "setItemTeamHumber", newState);
      return {
        ...newState,
        itemTeamHumber: action.peyload,
      };
    /////////////////////////////////////
    case Action.setItemMainHumber:
      handlePopup(action, "setItemMainHumber", newState);
      return {
        ...newState,
        itemMainHumber: action.peyload,
      };
    /////////////////////////////////////
    case Action.setPopupChatWindow:
      handlePopup(action, "setPopupChatWindow", newState);
      return {
        ...newState,
        popupChatWindow: action.peyload,
      };
    /////////////////////////////////////
    case Action.setPopupUserWindow:
      handlePopup(action, "setPopupUserWindow", newState);
      return {
        ...newState,
        popupUserWindow: action.peyload,
      };
    /////////////////////////////////////
    case Action.setPopupNotifyWindow:
      handlePopup(action, "setPopupNotifyWindow", newState);
      return {
        ...newState,
        popupNotifyWindow: action.peyload,
      };
    /////////////////////////////////////
    case Action.setPopupImage:
      handlePopup(action, "setPopupImage", newState);
      return {
        ...newState,
        popupImageWindow: action.peyload,
      };
    /////////////////////////////////////
    case Action.setPopupClose:
      handlePopup(action, "setPopupClose", newState);
      return {
        ...newState,
        popupCloseWindow: action.peyload,
      };
    /////////////////////////////////////
    case Action.setPopupMain:
      handlePopup(action, "setPopupMain", newState);
      return {
        ...newState,
        popupMainWindow: action.peyload,
      };
    case Action.setPopupMini:
      handlePopup(action, "setPopupMini", newState);
      return {
        ...newState,
        miniPopup: action.peyload,
      };
    case Action.toggleWinFriends:
      handlePopup(action, "toggleWinFriends", newState);
      return {
        ...newState,
        toggleLeft: action.peyload,
      };
    case Action.setSuggestLoad:
      return {
        ...newState,
        suggestLoad: action.peyload,
      };
    case Action.setLives:
      return {
        ...newState,
        lives: action.peyload,
      };
    case Action.setSocket:
      return {
        ...newState,
        socket: action.peyload,
      };
    case Action.setTitle:
      return {
        ...newState,
        title: setTitle(
          action.peyload.type,
          action.peyload.data,
          newState.title
        ),
      };
    ////////////////////////////////////
    case Action.setMessageNumber:
      return {
        ...newState,
        messageNumber: setNotifyNumber(
          action.peyload.type,
          action.peyload.data,
          newState.messageNumber
        ),
      };
    case Action.setMessages:
      return {
        ...newState,
        messages: setMessages(
          action.peyload.type,
          action.peyload.data,
          newState.messages
        ),
      };
    case Action.setChatUsers:
      return {
        ...newState,
        chatUsers: setMessages(
          action.peyload.type,
          action.peyload.data,
          newState.chatUsers
        ),
      };
    case Action.setCurrentRooms:
      return {
        ...newState,
        currentRooms: setMessages(
          action.peyload.type,
          action.peyload.data,
          newState.currentRooms
        ),
      };
    case Action.setScheduledRooms:
      return {
        ...newState,
        scheduledRooms: setMessages(
          action.peyload.type,
          action.peyload.data,
          newState.scheduledRooms
        ),
      };
    /////////////////////////////////////
    case Action.setReceivedFriends:
      return {
        ...newState,
        receivedFriends: handelSuggest(
          action.peyload.type,
          action.peyload.data,
          newState.receivedFriends
        ),
      };
    /////////////////////////////////////
    case Action.setReceivedTeams:
      return {
        ...newState,
        receivedTeams: handelSuggest(
          action.peyload.type,
          action.peyload.data,
          newState.receivedTeams
        ),
      };
    /////////////////////////////////////
    case Action.setRequestFriends:
      console.log("reducer -> action", action.peyload);
      return {
        ...newState,
        requestFriends: handelSuggest(
          action.peyload.type,
          action.peyload.data,
          newState.requestFriends
        ),
      };
    /////////////////////////////////////
    case Action.setRequestTeams:
      console.log("reducer -> action", action.peyload);
      return {
        ...newState,
        requestTeams: handelSuggest(
          action.peyload.type,
          action.peyload.data,
          newState.requestTeams
        ),
      };
    ////////////////////////////////////
    case Action.setMyStory:
      return {
        ...newState,
        myStory: setUser(
          action.peyload.type,
          action.peyload.data,
          newState.myStory
        ),
      };
    case Action.setCurrentStories:
      return {
        ...newState,
        currentStories: handelSuggest(
          action.peyload.type,
          action.peyload.data,
          newState.currentStories
        ),
      };
    case Action.setCurrentSeener:
      return {
        ...newState,
        currentSeener: handelSuggest(
          action.peyload.type,
          action.peyload.data,
          newState.currentSeener
        ),
      };
    ////////////////////////////////////
    case Action.setSuggestTeams:
      return {
        ...newState,
        suggestTeams: handelSuggest(
          action.peyload.type,
          action.peyload.data,
          newState.suggestTeams
        ),
      };
    case Action.setSuggestFriends:
      return {
        ...newState,
        suggestFriends: handelSuggest(
          action.peyload.type,
          action.peyload.data,
          newState.suggestFriends
        ),
      };

    case Action.setCallCenter:
      return {
        ...newState,
        callcenter: handelSuggest(
          action.peyload.type,
          action.peyload.data,
          newState.callcenter
        ),
      };
    case Action.setFollowers:
      return {
        ...newState,
        followers: handelSuggest(
          action.peyload.type,
          action.peyload.data,
          newState.followers
        ),
      };
    case Action.setFollowing:
      return {
        ...newState,
        followings: handelSuggest(
          action.peyload.type,
          action.peyload.data,
          newState.followings
        ),
      };
    case Action.setSuggestFollowers:
      return {
        ...newState,
        suggestFollowers: handelSuggest(
          action.peyload.type,
          action.peyload.data,
          newState.suggestFollowers
        ),
      };

    case Action.setTeamsUser:
      return {
        ...newState,
        teamsUser: action.peyload,
      };
    case Action.setTeamsJoin:
      return {
        ...newState,
        teamsJoin: action.peyload,
      };
    case Action.setFriendsUser:
      return {
        ...newState,
        friendsUser: handelSuggest(
          action.peyload.type,
          action.peyload.data,
          newState.friendsUser
        ),
      };
    case Action.setUser:
      return {
        ...newState,
        user: setUser(action.peyload.type, action.peyload.data, newState.user),
      };
    ///////////////////////////////////////
    case Action.setMessage:
      return {
        ...newState,
        message: action.peyload,
      };
    default:
      return newState;
  }
};

export default reducer;
