import { useState, useRef, useEffect, useCallback, memo } from 'react';
import Actions from '../reducer/actions';
import { useDispatch, useSelector } from 'react-redux';
import { InView } from 'react-intersection-observer';
//////////////////////////////////////////////
const ScrollBar = ({ pad, callback, scrollRef, detect = false, onLoad = () => { }, scrollDir = "vertical",
    handleOut = false, restore = false, observeHeight = false, className = "", onScrollBack, ...props }) => {
    const Scroll = useRef(null);
    const dispatch = useDispatch();
    const { main, sign } = useSelector(state => state);
    const [orgScrollHeight, setOrgScrollHeight] = useState(0);
    const [orgScrollTop, setOrgScrollTop] = useState(0);
    const [runTrue, setRunTrue] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    //////////////////////////////////////////
    const detecting = useCallback((isIn, where) => {
        if (isFirstLoad) return;
        let isStart = isIn && where === "start";
        let isEnd = isIn && where === "end";
        let scrollHeight = Scroll.current?.scrollHeight;
        let clientHeight = Scroll.current?.clientHeight;
        let scrollTop = Scroll.current?.scrollTop;
        if (runTrue) callback && callback({ isStart, isEnd });
        if (isStart) {
            setOrgScrollHeight(scrollHeight - clientHeight);
            setOrgScrollTop(scrollTop);
            Scroll.current.goTop();
        }
        if (isEnd) {
            Scroll.current.goBottom();
        }
    }, [runTrue, callback, Scroll.current, isFirstLoad]);
    //////////////////////////////////////////
    const setHandelStyle = useCallback(() => {
        if (!Scroll.current || !handleOut) return {};
        let handelWidth = (Scroll.current.offsetWidth - Scroll.current.clientWidth);
        if (handelWidth <= 0) return {};
        if (sign.isRTL) {
            return { "width": `calc(100% + ${handelWidth + 3}px)`, "paddingLeft": "3px" };
        } else {
            return { "width": `calc(100% + ${handelWidth + 3}px)`, "paddingRight": "3px" };
        }
    }, [Scroll.current, handleOut]);
    ////////////////////////////////////////////
    const scrolling = useCallback(() => {
        !runTrue && setRunTrue(true);
        isFirstLoad && setIsFirstLoad(false);
        onScrollBack && onScrollBack({ scrollTop: Scroll?.current?.scrollTop })
    }, [Scroll.current, runTrue, isFirstLoad, onScrollBack]);
    ////////////////////////////////////////////
    useEffect(() => {
        if (Scroll.current === null) return;
        scrollRef && scrollRef(Scroll.current);
        Scroll.current.fixedScroll = (callback, wait = 2000, runnr = true) => {
            if (runnr) {
                if (Scroll.current === null) return;
                let scrollHeight = Scroll.current?.scrollHeight - Scroll.current?.clientHeight;
                Scroll.current.scrollTop = orgScrollTop + (scrollHeight - orgScrollHeight);
            }
            window.clearTimeout(main.store.refTimeOut);
            main.store.refTimeOut = setTimeout(() => {
                callback && callback(Scroll?.current);
            }, wait);
        }
        Scroll.current.goBottom = (wiat = 50, interval = false) => {
            if (interval) {
                let setter = setInterval(() => {
                    if (Scroll.current === null) return;
                    Scroll.current.scrollTop = Scroll.current?.scrollHeight;
                }, 30);
                setTimeout(() => {
                    window.clearInterval(setter);
                }, wiat);
            } else {
                setTimeout(() => {
                    if (Scroll.current === null) return;
                    Scroll.current.scrollTop = Scroll.current?.scrollHeight;
                }, wiat)
            }
        }
        Scroll.current.goTop = (wiat = 0, interval = false) => setTimeout(() => {
            if (interval) {
                let setter = setInterval(() => {
                    if (Scroll.current === null) return;
                    Scroll.current.scrollTop = 0;
                }, 100);
                setTimeout(() => {
                    window.clearInterval(setter);
                }, wiat);
            } else {
                setTimeout(() => {
                    if (Scroll.current === null) return;
                    Scroll.current.scrollTop = 0;
                }, wiat)
            }
        }, wiat);
        Scroll.current.goTopSmooth = () => {
            if (Scroll.current === null) return;
            let scrollAnimation;
            const smooth = () => {
                if (Scroll.current === null) return clearTimeout(scrollAnimation || 0);
                var position = Scroll.current.scrollTop;
                if (position) {
                    Scroll.current.scrollBy(0, -Math.max(1, Math.floor(position / 4)));
                    scrollAnimation = setTimeout(smooth, 15);
                } else clearTimeout(scrollAnimation);
            }
            smooth();
        };
        Scroll.current.goto = (pos, wiat = 0) => setTimeout(() => {
            if (Scroll.current === null) return;
            Scroll.current.scrollTop = pos - Scroll.current.getBoundingClientRect().top;
        }, wiat)
        Scroll.current.moreThan = (pos) => {
            if (Scroll.current === null) return;
            let remaining = Scroll.current?.scrollHeight - (Scroll.current?.clientHeight + Scroll.current?.scrollTop);
            return (remaining >= pos);
        }
    }, [Scroll, orgScrollHeight, orgScrollTop]);
    ////////////////////////////////////////////
    // useEffect(() => {
    //     if (restore && main.scrollsPosition[restore] && main.restoreScroll) {
    //         if (Scroll.current === null) return;
    //         Scroll.current.scrollTop = main.scrollsPosition[restore] - Scroll.current.getBoundingClientRect().top;
    //     }
    // }, [Scroll, restore, main.restoreScroll]);
    ////////////////////////////////////////////
    useEffect(() => {
        if (!isFirstLoad) return;
        const waiter = setTimeout(() => {
            setIsFirstLoad(false);
        }, 5000);
        return () => clearTimeout(waiter);
    }, [Scroll.current, isFirstLoad])
    ////////////////////////////////////////////
    useEffect(() => {
        if (observeHeight === false) return;
        main.store.clientHeight = Scroll?.current?.clientHeight;
        const checkHeight = () => {
            if (!Scroll.current) return;
            let clientHeight = Scroll?.current?.clientHeight;
            if (clientHeight !== main.store.clientHeight) {
                main.store.clientHeight = clientHeight;
                Scroll.current.scrollTop = Scroll?.current?.scrollHeight;
            }
        }
        let setter = setInterval(checkHeight, 250);
        return () => window.clearInterval(setter);
    }, [Scroll, observeHeight, restore]);
    ////////////////////////////////////////////
    // useEffect(() => {
    //     main.store.positionScroll = 0;
    //     // dispatch(Actions.type("setScrolls", {
    //     //     key: restore,
    //     //     value: main.store.positionScroll
    //     // }));
    //     return () => {
    //         if (restore) {
    //             dispatch(Actions.type("setScrollsPosition", {
    //                 key: restore,
    //                 value: main.store.positionScroll
    //             }));
    //         }
    //     }
    // }, [restore]);
    ////////////////////////////////////////////
    return (<div ref={Scroll}
        className={`scroll-bar ${scrollDir}`}
        onLoad={onLoad}
        onScroll={scrolling}
        style={setHandelStyle()}>
        {detect && <InView as="div" className="detecting-observer"
            onChange={(isIn) => detecting(isIn, 'start')}></InView>}
        <div className={`inner-scroll-bar ${className}`} style={{ "padding": pad || "" }}>
            {props.children}
        </div>
        {detect && <InView as="div" className="detecting-observer bot"
            onChange={(isIn) => detecting(isIn, 'end')}></InView>}
    </div>)
}
///////////////////////////////////////////////
export default memo(ScrollBar);