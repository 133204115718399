import { useEffect, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import TextareaAutosize from "react-textarea-autosize";
import useDetectPhone from '../../../../../main/detectphone';
/////////////////////////////////////////////////////////
const MainCreatePostArea = ({ EditableRef, setEmojiPicker, showEmojiPicker,
    maxContent, row = 7, maxRow = 10, background, detectMax }) => {
    const { placeholder } = useSelector(state => state.sign.langData);
    const { detect } = useDetectPhone();
    const [DIR, setDir] = useState("auto");
    /////////////////////////////////////////////////
    useEffect(() => {
        EditableRef.current.focus();
    }, [EditableRef.current !== null]);
    /////////////////////////////////////////////////
    useEffect(() => {
        detectMax && detectMax(EditableRef.current.value.trim().length);
    }, [background])
    //////////////////////////////////////////////////
    return (<div className="form-grid" onClick={() => {
        if (detect && showEmojiPicker) setEmojiPicker(false);
    }}>
        <TextareaAutosize
            rows={row}
            maxRows={maxRow}
            autoCorrect="off"
            aria-autocomplete="none"
            className={`textarea ${DIR} _${background}`}
            dir={DIR}
            id="editablePost"
            placeholder={placeholder.someThing}
            spellCheck={false}
            ref={EditableRef}
            onKeyPress={(e) => {
                detectMax && detectMax(e.target.value.trim().length)
                let match = /[\u0590-\u083F]|[\u08A0-\u08FF]|[\uFB1D-\uFDFF]|[\uFE70-\uFEFF]/mg;
                (e.target.value.match(match) !== null) ? setDir("rtl") : setDir("ltr")
            }}
            onPaste={(e) => detectMax && detectMax(e.target.value.trim().length)}
            // onKeyUp={}
            maxLength={maxContent}
        />
    </div>)
}

export default memo(MainCreatePostArea);