import { useLayoutEffect, useState } from 'react';

export default () => {
    const [detect, setDetect] = useState(false);
    const [currnetHeight, setHeigth] = useState(0);
    const [platform, setPlatform] = useState('laptop');
    const [currnetWidth, setWidth] = useState(window.screen.width || window.innerWidth);
    useLayoutEffect(() => {
        const detectPhone = () => {
            let expressMop = (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|ipad|playbook|silk|Android|webOS|iPhone|iPod|IEMobile|Mobi/i);
            let isPhone = expressMop.test(navigator.userAgent) || expressMop.test(navigator.platform);
            let width = isPhone ? window.screen.width : window.innerWidth;
            let height = isPhone ? window.screen.height : window.innerHeight;
            let plat = '';
            if (width > 1400) plat = 'desktop';
            if (width <= 1400 && width > 1200) plat = 'custom';
            if (width <= 1200 && width > 1024) plat = 'laptop';
            if (width <= 1024 && width > 770) plat = 'tabpro';
            if (width <= 770 && width > 530) plat = 'tablet';
            if (width <= 530) plat = 'phone';
            setDetect(isPhone);
            setPlatform(plat);
            setWidth(width);
            setHeigth(height);
        }
        /////////////////////////////////
        detectPhone();
        window.addEventListener('resize', detectPhone);
        return () => window.removeEventListener('resize', detectPhone);
    }, []);
    return { detect, platform, currnetWidth, currnetHeight };
}