import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import PuffLoader from "react-spinners/PuffLoader";
import BarLoader from "react-spinners/BarLoader";
import ClockLoader from "react-spinners/ClockLoader";
import { useSelector } from "react-redux";
import './style.scss';
///////////////////////////////////
export const scaleLoader = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.5rem;
`;
export const puffLoader = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0rem;
`;

const handelColor = (clr, isDark, isRev) => {
    if (!clr) return "#040F16";
    if (isDark && isRev) {
        return clr === "drk" ? "#f2f2f2" : "#040F16";
    }
    else {
        return clr === "drk" ? "#040F16" : "#f2f2f2";
    }
}

export function ScaleMeetoor(props) {
    const isDark = useSelector(state => state.sign.isDark);
    let color = handelColor(props.clr, isDark, props.rev);

    return (<ScaleLoader
        css={scaleLoader}
        color={color}
        {...props}
    />)
}

export function BarMeetoor(props) {
    const isDark = useSelector(state => state.sign.isDark);
    let color = handelColor(props.clr, isDark, props.rev);

    return (<BarLoader
        css={scaleLoader}
        color={color}
        {...props}
    />)
}

export function ClockMeetoor(props) {
    const isDark = useSelector(state => state.sign.isDark);
    let color = handelColor(props.clr, isDark, props.rev);

    return (<ClockLoader
        css={scaleLoader}
        color={color}
        {...props}
    />)
}

export function PuffMeetoor(props) {
    const isDark = useSelector(state => state.sign.isDark);
    let color = handelColor(props.clr, isDark, props.rev);

    return (<PuffLoader
        css={puffLoader}
        color={color}
        {...props}
    />)
}

export function PropaMeetoor(props) {
    const isDark = useSelector(state => state.sign.isDark);
    let color = handelColor(props.clr, isDark, props.rev);
    return (<PropagateLoader
        css={scaleLoader}
        color={color}
        {...props}
    />)
}



export function OuterLoaderMeetoor(props) {
    return (<div className={`loader-class-main ${props.className}`}>
        {props.children}
    </div>)
}














// export function Transverse(props) {
//     const styleM = props.style ? props.style : {
//         "margin": "0rem",
//         "position": "relative",
//         "zIndex": "99999",
//         "height": "auto",
//         "width": "auto"
//     }
//     return (<div className={props.className}>
//         <TransverseLoading
//             style={styleM}
//             color={props.color}
//             size={props.size} />
//     </div>)
// }


// export function PointSpread(props) {
//     const styleM = props.style ? props.style : {
//         "margin": "0rem",
//         "position": "relative",
//         "zIndex": "99999",
//         "height": "auto",
//         "width": "auto",
//         "overflow": "inherit"
//     }
//     return (<div className={props.className}>
//         <PointSpreadLoading
//             style={styleM}
//             color={props.color}
//             size={props.size} />
//     </div>)
// }

// export function Common(props) {
//     const styleM = props.style ? props.style : {
//         "margin": "0rem",
//         "position": "relative",
//         "zIndex": "99999"
//     }
//     return (<div className={props.className}>
//         <CommonLoading
//             style={styleM}
//             color={props.color}
//             size={props.size} />
//     </div>)
// }

export function LoaderState({ loader, name }) {

    return (<>{loader && <div className="loader-state" >
        <div className="loadtext">
            {name}
        </div>
        <PropagateLoader
            css={scaleLoader}
            color={"#1f0e41"}
            loading={true}
        />
        <div className="loadtext">{loader}</div>
    </div>}</>)
}
