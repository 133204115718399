import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MinLogoSvg, SendSvg } from '../dashbord/icons/icons';
import './style.scss';

////////////////////////////////////////////////
const Arct = ({ name }) => {
    return (<div className="link">
        {name}
    </div>)
}
////////////////////////////////////////////////
const Anrm = ({ icon, name, href }) => {
    return (<a className="link" href={href} target="_blank">
        <img src={process.env.PUBLIC_URL + icon} alt={name} />
        {name}
    </a>)
}
////////////////////////////////////////////////
export default () => {
    const isRTL = useSelector(state => state.sign.isRTL);
    const { features, services, follow, aboutus } = useSelector(state => state.sign.langData.footerData);
    ////////////////////////////////////////////
    return (
        <div className={`meetoor-footer ${isRTL ? "RTL" : ""}`}>
            <MinLogoSvg className="drk imgback-meetoor" size={500} />
            <div className="container">
                <div className="footer-links">

                    <div className="links">
                        <h1>{features.head}</h1>
                        {
                            features.items.map((name, key) => {
                                return (<Arct key={key + "anony_" + name} name={name} />)
                            })
                        }
                    </div>
                    <div className="links">
                        <h1>{services.head}</h1>
                        {
                            services.items.map((name, key) => {
                                return (<Arct key={name + "anony_" + key} name={name} />)
                            })
                        }
                    </div>
                    <div className="links">
                        <h1>{follow.head}</h1>
                        {
                            follow.items.map((data, key) => {
                                return (<Anrm key={key + "follow_" + data.href} {...data} />)
                            })
                        }
                    </div>
                    <div className="links">
                        <h1>{aboutus.head}</h1>
                        <div className="link">
                            {aboutus.info}
                        </div>
                        <Link to="/contact_us" className="link contact">
                            <span className="ex-mail">
                                {aboutus.links[0]}
                            </span>
                            <span className="sender">
                                <SendSvg className="lit" size="20" />
                            </span>
                        </Link>
                        <Link to="/policy" className="link privacy">
                            {aboutus.links[1]}
                        </Link>
                    </div>
                </div>
            </div>
            <div className="footer-copy">
                &#169; 2021 meetoor.com all rights reserved
            </div>
        </div>
    )
}