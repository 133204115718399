import moment from 'moment-timezone';
/////////////////////////////////////
class CreateTime {
    constructor() {
        this.utc = "";
        this.format = "";
        this.timeZone = moment.tz.guess();
    }
    setDate(utc, format = "DD/MM/YYYY-HH:mm:ss") {
        this.format = format;
        this.localTime = moment.utc(utc).tz(this.timeZone).format(this.format);
        this.fromNow = moment(this.localTime, this.format).fromNow();
        this.dateSML = moment(this.localTime, this.format)
            .format("MMM DD_hh:mm A").replace('_', ' at ');
        this.dateTime = moment(this.localTime, this.format)
            .format("hh:mm A").replace('_', ' at ');
        this.dateBIG = moment(this.localTime, this.format)
            .format("dddd, MMM DD, YYYY_hh:mm A").replace('_', ' at ');
        return this;
    }

    get ago() {
        return this.fromNow.replace(" ago", "")
    }

    get agoMinute() {
        switch (true) {
            case this.fromNow.includes('second'):
            case this.fromNow.includes('minute'):
                return "minutes";
            default:
                return this.fromNow;
        }
    }

    get toNow() {
        switch (true) {
            case this.fromNow.includes('second'):
            case this.fromNow.includes('minute'):
            case this.fromNow.includes('hour'):
                return this.fromNow;
            default:
                return this.dateSML;
        }
    }

    get toDay() {
        switch (true) {
            case this.fromNow.includes('second'):
            case this.fromNow.includes('minute'):
            case this.fromNow.includes('hour'):
                return this.dateTime;
            default:
                return this.dateBIG;
        }
    }

    get fullDate() {
        return this.dateBIG;
    }
}

export default new CreateTime();