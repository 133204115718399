import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useDetectPhone from '../../main/detectphone';
import {
    MainLogoSvg, MinLogoSvg
} from '../dashbord/icons/icons';
import '../dashbord/dashbord.scss';
import '../dashbord/header/header.scss';
import OnePostView from '../dashbord/branches/onepostview/onepostview';
///////////////////////////////////////////////////
const PublicPost = () => {
    const lang = useSelector(state => state.sign.lang);
    const isDark = useSelector(state => state.sign.isDark);
    const { buttons } = useSelector(state => state.sign.langData);
    const { currnetWidth } = useDetectPhone();
    //////////////////////////////////////////
    return (<div className="meetoor-dashbord meetoor">
        <div className="dashbord-outer-header">
            {currnetWidth >= 770 && <div className="dashbord-tabs-logo">
                <Link className="logo" to={`/home`}>
                    <MainLogoSvg className={`${isDark ? "lit" : "drk"}`} isArabic={lang === "AR"} />
                </Link>
            </div>}
            <div className="dashbord-outer-inner">
                <div className="dashbord-inner-header">

                    {currnetWidth <= 770 && <>
                        <Link className="logo min" to={`/home`}>
                            <MinLogoSvg className={isDark ? "lit" : "drk"} />
                        </Link>
                    </>}

                    <div className="live-meetoor-fromout">
                        <button className="header user" >
                            <Link className="nav-link signin" to="/signin">
                                {buttons.signin}
                            </Link>
                        </button>
                        <button className="header user" >
                            <Link className="nav-link signup" to="/signup">
                                {buttons.signup}
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div className="dashbord-fixed-home" style={{ width: "100%", paddingTop: "0.8rem" }}>
            <div className="continar-bottom">
                <OnePostView anony={true} />
            </div>
        </div>
    </div>)
}

export default PublicPost;