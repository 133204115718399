import Action from "../actions";
import {
    update,
    setNotifyNumber,
    setMessages
} from '../helper'
////////////////////////////////////////
const initState = {
    notification: [],
    notifyNumber: 0,
    popup: [],
    toast: undefined,
    roomAlerts: [],
};

export default (state = initState, action) => {
    const newState = state;
    switch (action.type) {
        case Action.setNotifyNumber:
            return {
                ...newState,
                notifyNumber: setNotifyNumber(action.peyload.type, action.peyload.data, newState.notifyNumber)
            }
        case Action.setNotification:
            return {
                ...newState,
                notification: update(action.peyload.type, action.peyload.data, newState.notification)
            }
        case Action.setNotifiPopup:
            return {
                ...newState,
                popup: setMessages(action.peyload.type, action.peyload.data, newState.popup)
            }
        case Action.setRoomAlerts:
            return {
                ...newState,
                roomAlerts: setMessages(action.peyload.type, action.peyload.data, newState.roomAlerts)
            }
        case Action.setToast:
            return {
                ...newState,
                toast: action.peyload
            }
        default:
            return newState;
    }
}