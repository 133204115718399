import { useRef, memo, Suspense, lazy } from 'react';
import { PuffMeetoor } from '../../../../main/loadshap/loadshap';
//////////////////////////////////////////////////////
const OnePostTop = lazy(() => import('./postTop'));
const OnePostBody = lazy(() => import('./postBody'));
const OnePostBottom = lazy(() => import('./postBottom'));
//////////////////////////////////////////////////////
const OnePost = ({
    comments,
    postText,
    postFile,
    keyArray,
    // UserPhoto,
    posterPhoto,
    posterName,
    posterUsername,
    postId,
    sticky,
    likes,
    date,
    secure,
    isFriend,
    isFollowing,
    teamid,
    IsVideo,
    className = "",
    likeType = "heart",
    isUser = true,
    isPin,
    background,
    isOwner = false,
    isSecure = false,
    isliked = false,
    info,
    isEdit,
    isSaved,
    refId,
    isCopy = false,
    clones = 0,
    pinned,
    anony = false,
    clonedUsername = false
}) => {
    const cloneRef = useRef(null);
    //////////////////////////////////////////////////////////
    return (<>
        <div className={`post ${className} ${refId ? "no-margin" : ""} ${pinned ? "pinned" : ""}`} >
            <Suspense fallback={<PuffMeetoor size={60} loading={true} clr="drk" rev={true} />}>
                <OnePostTop postText={postText}
                    keyArray={keyArray}
                    posterPhoto={posterPhoto}
                    posterName={posterName}
                    posterUsername={posterUsername}
                    postId={postId}
                    sticky={sticky}
                    date={date}
                    isUser={isUser}
                    isOwner={isOwner}
                    isSecure={isSecure}
                    info={info}
                    background={background}
                    secure={secure}
                    teamid={teamid}
                    isEdit={isEdit}
                    isSaved={isSaved}
                    isFriend={isFriend}
                    isFollowing={isFollowing}
                    refId={refId}
                    isPin={isPin}
                    pinned={pinned} />
            </Suspense>

            <Suspense fallback={<PuffMeetoor size={60} loading={true} clr="drk" rev={true} />}>
                <OnePostBody postText={postText}
                    keyArray={keyArray}
                    posterPhoto={posterPhoto}
                    posterName={posterName}
                    postId={postId}
                    postFile={postFile}
                    IsVideo={IsVideo}
                    refId={refId}
                    anony={anony}
                    background={background}
                    cloneRef={cloneRef} />
            </Suspense>

            <Suspense fallback={<PuffMeetoor size={60} loading={true} clr="drk" rev={true} />}>
                <OnePostBottom likes={likes}
                    keyArray={keyArray}
                    comments={comments}
                    clones={clones}
                    postId={postId}
                    isUser={isUser}
                    likeType={likeType}
                    isliked={isliked}
                    refId={refId}
                    isCopy={isCopy}
                    cloneRef={cloneRef}
                    clonedUsername={clonedUsername}
                    anony={anony} />
            </Suspense>
        </div>
    </>)
}
//////////////////////////////////////////////////////
export default memo(OnePost);