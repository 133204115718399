import ReactDOM from 'react-dom';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducer from './reducer/index';
import './index.css';

///////////////////////////////////////////////
const store = createStore(reducer);

if (process.env.NODE_ENV !== "development") {
  console.log = console.info = console.warn = console.error = console.debug = () => null;
  // console.info = console.warn = console.error = console.debug = () => { };
}
// 
ReactDOM.render(<Provider store={store}>
  {/* <React.StrictMode> */}
  <App />
  {/* </React.StrictMode> */}
</Provider>
  , document.getElementById('meetoor-company'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
