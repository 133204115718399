import Action from "../actions";
////////////////////////////////////////
import {
    getData,
    saveData,
    checkData,
    delData,
    Accounts,
    getDataForLang
} from '../helper'
////////////////////////////////////////
const langs = {
    AR: {
        name: "العربية",
        key: "AR"
    },
    EN: {
        name: "English",
        key: "EN"
    },
    TR: {
        name: "Türkçe",
        key: "TR"
    },
    CH: {
        name: "中文",
        key: "CH"
    },
    FR: {
        name: "française",
        key: "FR"
    }
}
//////////////////////////////////////////
const returnLang = (lang = "AR") => {
    return langs[lang] ? langs[lang].key : "AR";
}
const currentLang = checkData("lang") ? getData("lang") : "AR";
const currentMode = checkData("mode") ? getData("mode") : false;
//////////////////////////////////////////
const initState = {
    accounts: Accounts(),
    token: getData("token"),
    loggedIn: checkData("token"),
    isDark: currentMode,
    langs,
    lang: returnLang(currentLang),
    langData: getDataForLang(returnLang(currentLang)),
    isRTL: returnLang(currentLang) === "AR",
    versionApp: 0.0,
    versionStored: 0.0,
    isApp: false,
    renderHome: true,
    goURL: false,
    CtrUpdateStories: true,
    sendMessage: (msg) => {
        const message = JSON.stringify(msg)
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(message);
        }
    }
};

const reducer = (state = initState, action) => {
    const newState = state;
    switch (action.type) {
        /////////////////////////////////////
        case Action.setMeetoorLang:
            let lang = returnLang(action.peyload);
            saveData("lang", lang);
            return {
                ...newState,
                lang: lang,
                langData: getDataForLang(lang),
                isRTL: lang === "AR"
            }
        /////////////////////////////////////
        case Action.setVersionApp:
            return {
                ...newState,
                versionApp: action.peyload
            }
        /////////////////////////////////////
        case Action.setCtrUpdateStories:
            return {
                ...newState,
                CtrUpdateStories: action.peyload
            }
        /////////////////////////////////////
        case Action.setRenderHome:
            return {
                ...newState,
                renderHome: action.peyload
            }
        /////////////////////////////////////
        case Action.setVersionStored:
            return {
                ...newState,
                versionStored: action.peyload
            }
        /////////////////////////////////////
        case Action.setDarkMode:
            saveData("mode", action.peyload);
            initState.sendMessage({ mode: action.peyload, type: "mode" });
            return {
                ...newState,
                isDark: action.peyload
            }
        /////////////////////////////////////
        case Action.setIsApp:
            return {
                ...newState,
                isApp: action.peyload
            }
        /////////////////////////////////////
        case Action.setGoURL:
            return {
                ...newState,
                goURL: action.peyload
            }
        /////////////////////////////////////
        case Action.setAccounts:
            return {
                ...newState,
                accounts: Accounts(action.peyload)
            }
        /////////////////////////////////////
        case Action.delAccounts:
            return {
                ...newState,
                accounts: Accounts(null, action.peyload)
            }
        case Action.logout:
            delData("token");
            return {
                ...newState,
                loggedIn: false,
                token: undefined
            }
        case Action.login:
            saveData("token", action.peyload)
            return {
                ...newState,
                loggedIn: true,
                token: action.peyload
            }
        ///////////////////////////////////////
        default:
            return newState;
    }
}

export default reducer;