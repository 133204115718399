import { useCallback, useRef, memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom";
import VideoPlayerMain from '../main/plyr-video/player';
import $ from '../main/getter';
import Actions from '../reducer/actions';
import Axios from "axios";
import fileDownload from 'js-file-download';
import {
  ArrowSvg, CloseSvg, DownloadSvg, ReloadSvg
} from '../components/dashbord/icons/icons';
import './popupmain.scss';
//////////////////////////////////////////////////
export const MiniPopup = memo(() => {
  const miniPopup = useSelector(state => state.main.miniPopup);
  const isRTL = useSelector(state => state.sign.isRTL);
  const isDark = useSelector(state => state.sign.isDark);
  const { buttons } = useSelector(state => state.sign.langData);
  const dispatch = useDispatch();
  const classBack = miniPopup.classBack === undefined ? "" : miniPopup.classBack;
  ////////////////////////////////////////////////
  if (miniPopup.html) return (<div className={`close-popup-window ${isDark ? "DRK" : ""} ${isRTL ? "RTL" : ""} ${classBack}`}>
    <div className="close-popup minipopup">
      <div className={miniPopup.className} dir="auto">
        {miniPopup.html}
      </div>
      {(miniPopup.handle || miniPopup.close) ? <div className="outer-button">
        {miniPopup.handle ? <button type="button" onClick={(e) => {
          miniPopup.handle();
        }}>{
            miniPopup.handleName ? miniPopup.handleName : buttons.okay
          }</button> : null}
        {miniPopup.close ? <button type="button" className="close" onClick={(e) => {
          miniPopup.close();
          dispatch(Actions.type("setPopupMini", false));
        }}>{
            miniPopup.closeName ? miniPopup.closeName : buttons.close
          }</button> : null}
      </div> : null}
    </div>
  </div>);
  return null;
});
//////////////////////////////////////////////////
export const ClosePopup = memo(() => {
  const popupCloseWindow = useSelector(state => state.main.popupCloseWindow);
  const isRTL = useSelector(state => state.sign.isRTL);
  const isDark = useSelector(state => state.sign.isDark);
  const { buttons, messages } = useSelector(state => state.sign.langData);
  const dispatch = useDispatch();
  ////////////////////////////////////////////////
  if (popupCloseWindow) return (<div className={`close-popup-window ${isRTL ? "RTL" : ""} ${isDark ? "DRK" : ""}`}>
    <div className="close-popup">
      <span>{messages.sure}</span>
      <div className="outer-button">
        <button type="button" onClick={(e) => {
          dispatch(Actions.type("setPopupClose", false));
        }}>{buttons.back}</button>
        <button type="button" className="close" onClick={(e) => {
          popupCloseWindow();
          dispatch(Actions.type("setPopupClose", false));
        }}>{buttons.okay}</button>
      </div>
    </div>
  </div>);
  return null;
});
///////////////////////////////////////////////////
export const MainPopup = memo(() => {
  const popupMainWindow = useSelector(state => state.main.popupMainWindow);
  const isRTL = useSelector(state => state.sign.isRTL);
  const isDark = useSelector(state => state.sign.isDark);
  const dispatch = useDispatch();
  const closeWindow = (e) => {
    if (popupMainWindow.noclose) return;
    if (popupMainWindow.fastclose) {
      if ($(e.target).attr('name') !== "close") return;
      dispatch(Actions.type("setPopupMain", false));
      return;
    }
    if ($(e.target).attr('name') !== "close") return;
    dispatch(Actions.type("setPopupClose", () => {
      dispatch(Actions.type("setPopupMain", false));
    }));
  }
  let appClass = `main-popup-window ${isRTL ? "RTL" : ""} ${isDark ? "DRK" : ""}`;
  appClass = popupMainWindow.noToken ? "main-popup-window noToken" : appClass;
  ////////////////////////////////////////////////
  ////////////////////////////////////////////////
  return (<div className={appClass}
    name="close" onClick={closeWindow}>
    <div className={`outer-popup-window ${popupMainWindow.className}`}>
      {popupMainWindow.html}
    </div>
  </div>);
});
//////////////////////////////////////////////////
export const ImagePopup = memo(() => {
  const token = useSelector(state => state.sign.token);
  // const isApp = useSelector(state => state.sign.isApp);
  // const sendMessage = useSelector(state => state.sign.sendMessage);
  const popupImageWindow = useSelector(state => state.main.popupImageWindow);
  const dispatch = useDispatch();
  const [src, setSrc] = useState("");
  const [index, setIndex] = useState(0);
  const isMoreOne = popupImageWindow?.urls?.length > 1;
  ////////////////////////////////////////////////
  const closeWindow = (e) => {
    if ($(e.target).attr('name') !== "close") return;
    dispatch(Actions.type("setPopupImage", false));
  }
  const downloadFile = () => {
    let data = src?.url ? src.url : src;
    let name = data.split("/").pop();
    console.log("🚀 ~ file: helperApp.js ~ line 115 ~ downloadFile ~ name", name)
    // if (isApp) sendMessage({
    //   type: "save-file",
    //   url: tabsData.audioRoom,
    //   name: infoRoom.header,
    //   token
    // });
    // else 
    try {
      Axios.get(data, {
        headers: {
          'Authorization': `Token ${token}`
        },
        responseType: 'blob',
      }).then(res => {
        fileDownload(res.data, name);
      });
    } catch (e) {
      console.error(e)
    }
  }
  /////////////////////////////////////////////
  const pinchRef = useRef();
  const imgRef = useRef();
  const onUpdate = useCallback(({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty("transform", value);
    }
  }, [make3dTransformValue, imgRef]);
  useEffect(() => {
    if (!popupImageWindow) return;
    setSrc(popupImageWindow?.urls[popupImageWindow?.index]);
    setIndex(popupImageWindow?.index);
    console.log("🚀 index", popupImageWindow?.index)
    return () => setSrc("");
  }, [popupImageWindow]);
  /////////////////////////////////////////////
  let appClass = "image-popup-window";
  if (popupImageWindow) return (<div className={appClass}>
    <div name="close" onClick={closeWindow} className={`overlay-fillter ${src?.isVideo ? "black" : ""}`}
      style={{ "backgroundImage": `url(${src?.url ? src?.url : src})` }} />
    <button className="close-popup" onClick={() => dispatch(Actions.type("setPopupImage", false))}>
      <CloseSvg className="lit" size={20} />
    </button>
    <button className="download-popup" onClick={downloadFile} >
      <DownloadSvg className="drk" size={20} />
    </button>
    <button className="reset-popup" onClick={() => {
      let pinch = pinchRef.current;
      pinch._zoomFactor = 1;
      pinch._offset = { x: 0, y: 0, scale: 1 };
      let value = make3dTransformValue(pinch._offset);
      imgRef.current.style.setProperty("transform", value);
    }} >
      <ReloadSvg className="lit" size={22} />
    </button>
    {isMoreOne ? <>
      <button className="arrow-ctr left" onClick={() => {
        let currnet = index;
        if (index >= (popupImageWindow.urls.length - 1)) {
          currnet = 0;
          setIndex(currnet);
        }
        else {
          currnet = index + 1;
          setIndex(currnet);
        }
        setSrc(popupImageWindow.urls[currnet]);
      }}>
        <ArrowSvg className="drk" size={20} />
      </button>
      <button className="arrow-ctr right" onClick={() => {
        let currnet = index;
        if (index <= 0) {
          currnet = popupImageWindow.urls.length - 1;
          setIndex(currnet);
        }
        else {
          currnet = index - 1;
          setIndex(currnet);
        }
        setSrc(popupImageWindow.urls[currnet]);
      }}>
        <ArrowSvg className="drk" size={20} />
      </button>
    </> : null}
    <div className={`outer-img-preview${popupImageWindow.avatar ? " avatar" : ""}`}>

      <QuickPinchZoom ref={pinchRef} onUpdate={onUpdate} animationDuration={0}>
        {src?.url && src?.isVideo ? <span ref={imgRef}>
          <VideoPlayerMain src={src?.url} className={`post-file no-viewer`} normal={false} />
        </span> :
          <img ref={imgRef} src={src?.url ? src?.url : src} alt="current-popup-img" />}
      </QuickPinchZoom>
    </div>
  </div>);
  else return null;
});