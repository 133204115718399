import Action from "../actions";
import {
    reobject,
} from '../helper'
////////////////////////////////////////
const initState = {
    teams: {},
};

export default (state = initState, action) => {
    const newState = state;
    switch (action.type) {
        /////////////////////////////////////
        case Action.setTeams:
            return {
                ...newState,
                teams: reobject(action.peyload.type, action.peyload.data, newState.teams)
            }
        default:
            return newState;
    }
}