import { PayPalButton } from "react-paypal-button-v2";
import { useLocation, useHistory } from 'react-router-dom';
import QueryString from 'query-string';
import Axios from "../main/Axios";
import './paypal.scss';
/////////////////////////////////////////
export default function RenderPayPal() {
    const history = useHistory();
    const { search } = useLocation();
    const { token, prizeId, clientId } = QueryString.parse(search);
    return (<div className="paypal-window">
        <PayPalButton options={{ clientId }}
            createOrder={() => {
                return Axios.post("CreatePayment/", {
                    prizeId
                }, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                }).then(res => {
                    console.log("🚀 ~ file: paypal.js ~ line 32 ~ RenderPayPal ~ res", res.data);
                    return res.data.id;
                });
            }}
            onApprove={(data) => {
                return Axios.post("CapturePayment/", {
                    prizeId,
                    orderId: data.orderID
                }, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                }).then((res) => {
                    history.push("/paypal/?done");
                    console.log("🚀 ~ file: paypal.js ~ line 42 ~ RenderPayPal ~ res", res)
                    return res.data;
                });
            }}
            onError={(err) => {
                history.push("/paypal/?done");
            }}
        />
    </div>);
}
