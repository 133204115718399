import Action from "../actions";
import {
    updateObj,
    // getData,
    // saveData,
    reobject,
    setMessages
} from '../helper'
////////////////////////////////////////
const initState = {
    posts: {},
    chatTeam: {},
    chats: {},
    comments: {},
    likes: {},
    replys: {},
    likesReply: {},
    otherPosts: {},
    otherComments: {},
    meetoorTips: undefined,
    trends: []
};

export default (state = initState, action) => {
    const newState = state;
    let preparToSave;
    switch (action.type) {
        case Action.setMeetoorTips:
            return {
                ...newState,
                meetoorTips: setMessages(action.peyload.type, action.peyload.data, newState.meetoorTips)
            }

        case Action.setOtherPosts:
            return {
                ...newState,
                otherPosts: reobject(action.peyload.type, action.peyload.data, newState.otherPosts)
            }
        case Action.setTrends:
            return {
                ...newState,
                trends: action.peyload
            }

        case Action.setOtherComments:
            return {
                ...newState,
                otherComments: reobject(action.peyload.type, action.peyload.data, newState.otherComments)
            }
        case Action.setChatsTeam:
            preparToSave = updateObj(action.peyload.type, action.peyload.data, newState.chatTeam);
            // saveData("chatTeam", preparToSave);
            return {
                ...newState,
                chatTeam: preparToSave
            }
        case Action.setChats:
            preparToSave = updateObj(action.peyload.type, action.peyload.data, newState.chats);
            // saveData("chats", preparToSave);
            return {
                ...newState,
                chats: preparToSave
            }
        case Action.setPosts:
            preparToSave = updateObj(action.peyload.type, action.peyload.data, newState.posts);
            // saveData("posts", preparToSave);
            return {
                ...newState,
                posts: preparToSave
            }
        case Action.setComments:
            return {
                ...newState,
                comments: updateObj(action.peyload.type, action.peyload.data, newState.comments)
            }
        case Action.setLikes:
            return {
                ...newState,
                likes: updateObj(action.peyload.type, action.peyload.data, newState.likes)
            }
        case Action.setReplys:
            return {
                ...newState,
                replys: updateObj(action.peyload.type, action.peyload.data, newState.replys)
            }
        case Action.setLikesReply:
            return {
                ...newState,
                likesReply: updateObj(action.peyload.type, action.peyload.data, newState.likesReply)
            }

        default:
            return newState;
    }
}