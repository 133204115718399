//////////////////////////////////////////////////////
export default ({ className }) => {
    return (<div className={`post-loader post ${className}`}>
        <div className="info-content">
            <div className="link">
                <div className="link_user">
                    <div className="user-img"></div>
                    <div className="user-info">
                        <h1 />
                        <div className="skil"></div>
                    </div>
                </div>
            </div>
            <button className="more" />
        </div>
        <div className="post-content">
            <div className="post-text" />
            <div className="post-file" />
        </div>
        <div className="react-content">
            <div className="like-section">
                <button className="like" />
                <button className="number" />
            </div>

            <div className="other-section">
                <div className="likes-num-section" />

                <div className="space-line" />
                <div className="comment-copy-section">
                    <div className="comment-section">
                        <div className="comment" />
                    </div>
                </div>
            </div>
            {/* <div className="comment-section">
                <button className="number" />
            </div>

            <div className="comment-section">
                <button className="number" />
            </div> */}
        </div>
    </div>)
}