import { useState, useRef } from 'react';
import { NavLink as NavLinkT, Link as LinkT } from 'react-router-dom';
import { useOuterClick } from 'react-outer-click';
import useDetectPhone from '../../main/detectphone';
import { useSelector } from 'react-redux';
import { HumberSvg, MainLogoSvg, MinLogoSvg } from '../dashbord/icons/icons';
import './style.scss';
/////////////////////////////////////////////
const Link = ({ name, href }) => (
    <LinkT exact="true" to={href} className="nav" >
        {name}
    </LinkT>
);
const NavLink = ({ name, href }) => (
    <NavLinkT exact to={href} className="nav" activeClassName="active" >
        {name}
    </NavLinkT>
);
//////////////////////////////////////////////
function Navigation() {
    const { viewHeadData } = useSelector(state => state.sign.langData);
    ///////////////////////////////////
    return (
        <div className='nav-navigation'>
            <div className="nav-link" >
                <NavLink name={viewHeadData[0]} href="/" />
            </div>
            <div className="nav-link" >
                <NavLink name={viewHeadData[1]} href="/join" />
            </div>
            <div className="nav-link" >
                <NavLink name={viewHeadData[2]} href="/contact_us" />
            </div>
            <div className="nav-link signin" >
                <Link name={viewHeadData[3]} href="/signin" />
            </div>
            <div className="nav-link signup" >
                <Link name={viewHeadData[4]} href="/signup" />
            </div>
        </div>
    )
}
//////////////////////////////////////////////
export default () => {
    const isDark = useSelector(state => state.sign.isDark);
    const lang = useSelector(state => state.sign.lang);
    const { platform, currnetWidth } = useDetectPhone();
    const [humbr, setHumbr] = useState(false);
    const outerRef = useRef();
    /////////////////////////////////////////
    useOuterClick(outerRef, (e) => {
        if (humbr) {
            console.log("e", e.target)
            let thisElem = document.querySelector('#humberToggleSideMain');
            let targetElem = e.target;
            // if (thisElem === targetElem) return;
            if (thisElem.contains(targetElem)) return;
            setHumbr(false);
        }
    });
    //////////////////////////////////////////
    return (<nav className={"meetoor-nav"}>
        <div className="container">
            <LinkT className="nav-logo" to="/">
                {platform === "phone" ? <MinLogoSvg className="drk" size={40} /> : <MainLogoSvg className="drk" isArabic={lang === "AR"} />}
            </LinkT>

            <div ref={outerRef} className={`navList ${humbr ? "active" : ""}`} >
                <Navigation />

                {(currnetWidth <= 770) &&
                    <div className="footer-meetoor">
                        meetoor<span>&trade;&nbsp;&#169; 2020</span>
                    </div>}
            </div>

            {(currnetWidth <= 770) &&
                <button id="humberToggleSideMain" className={`humber`}
                    onClick={() => setHumbr(!humbr)}>
                    <HumberSvg size="27" className={`${isDark ? "lit" : "drk"} ${humbr ? "humberAnim" : ""}`} />
                </button>
            }
        </div>
    </nav>)
};