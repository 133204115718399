import { useEffect, memo, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import Actions from '../../../../reducer/actions';
import Axios from '../../../../main/Axios';
import QueryString from 'query-string';
import ScrollBar from '../../../../main/scrollbar';
import Post from '../../home/posts/post';
import TempPostLoad from '../../home/posts/postloader';
// import "./onepostview.scss";
/////////////////////////////////////////////////
const MainPostCommentView = ({ anony = false }) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = QueryString.parse(location.search);
    console.log("🚀 ~ file: onepostview.js ~ line 17 ~ MainPostCommentView ~ id", id)
    const User = useSelector(state => state.main.user);
    const { messages } = useSelector(state => state.sign.langData);
    const token = useSelector(state => state.sign.token);
    const posts = useSelector(state => state.posts.posts);
    const userPhoto = User.UserPhoto;
    const keyArray = "postsnotification";
    const postData = posts[keyArray];
    //////////////////////////////////////////////
    const getPostDataApi = useCallback(async () => {
        try {
            const response = await Axios.get(`getPost/?postid=${id}`, {
                headers: anony ? null : {
                    'Authorization': `Token ${token}`
                }
            });

            if (typeof response.data === "object") {
                dispatch(Actions.type("setPosts", {
                    type: 'set',
                    data: {
                        key: keyArray,
                        val: [response.data]
                    }
                }));
            } else setTimeout(() => {
                history.push('/home');
            }, 100);

        } catch (e) {
            console.log("signin -> catch", e)
        }
    }, [id]);
    //////////////////////////////////////////////
    useEffect(() => {
        getPostDataApi();
        return () => dispatch(Actions.type("setPosts", {
            type: 'delete',
            data: {
                key: keyArray,
            }
        }));
    }, [id]);
    //////////////////////////////////////////////
    return (<div className="window-posts from-notificaion">
        <div className={`posts-outer ${anony ? "auto" : ""}`}>
            <ScrollBar handleOut={true}>
                <div className="left-section-post">
                    {anony ? null : <div className={`notes-for-posts`}>
                        {messages.fromNotices}
                    </div>}
                    {(postData === undefined) ? <TempPostLoad className="" /> :
                        postData[0] === undefined ? <TempPostLoad className="" /> :
                            <>
                                <Post {...postData[0]} anony={anony} UserPhoto={userPhoto} keyArray={keyArray} />
                            </>}
                </div>
            </ScrollBar>
        </div>
    </div>)
}
export default memo(MainPostCommentView);