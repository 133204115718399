import {
    memo, useCallback, useEffect,
    useRef, useState
} from "react";
import { useDispatch } from "react-redux";
import Plyr from "plyr-react";
import { InView } from 'react-intersection-observer';
import Actions from "../../reducer/actions";
import { ZoomInSvg } from "../../components/dashbord/icons/icons";
import "plyr-react/dist/plyr.css";
import "./videoplayer.scss";
////////////////////////////////////////////////
const VideoPlayerMain = ({ src, className = '', type = undefined, mainType = "video",
    normal = true, frame, noControl = false, autoPlay = false, ...props }) => {
    const refPlyr = useRef();
    const dispatch = useDispatch();
    const [Player, setPlayer] = useState(false);
    ////////////////////////////////////////////
    const openImageAsBig = useCallback((index) => {
        dispatch(Actions.type("setPopupImage", {
            className: 'sml',
            index,
            urls: [{ url: src, isVideo: true }]
        }));
    }, [src]);
    ////////////////////////////////////////////
    useEffect(() => {
        if (autoPlay && refPlyr.current?.plyr) {
            setTimeout(() => {
                refPlyr.current?.plyr?.play();
            }, 100);
        }
    }, [autoPlay, refPlyr.current]);
    // ////////////////////////////////////////////
    // useEffect(() => {
    //     if (refPlyr.current?.plyr) {
    //         console.log("🚀 ~ file: player.js ~ line 37 ~ useEffect ~ refPlyr.current", refPlyr.current)
    //         setPlayer(refPlyr.current.plyr);
    //     }
    // }, [refPlyr.current.plyr]);
    // useEffect(() => {
    //     if (autoPlay && Player) {
    //         setTimeout(() => {
    //             Player?.play();
    //         }, 100);
    //     }
    // }, [autoPlay, Player]);
    // // ////////////////////////////////////////////
    // useEffect(() => {
    //     if (refPlyr.current?.plyr) {
    //         console.log("🚀 ~ file: player.js ~ line 37 ~ useEffect ~ refPlyr.current", refPlyr.current)
    //         setPlayer(refPlyr.current.plyr);
    //     }
    // }, [refPlyr.current.plyr]);
    ////////////////////////////////////////////
    return (<div className={`meetoor-video-player ${className} ${mainType === "video" ? "viewer" : ""}`} {...props} >
        {normal ? <InView className="detecting-observer"
            onChange={(isIn) => {
                if (autoPlay) return;
                (!isIn) && refPlyr.current?.plyr?.pause();
            }} /> : null}
        <Plyr
            ref={refPlyr}
            source={{
                type: mainType,
                poster: frame ? frame : "",
                sources: [
                    {
                        src,
                        provider: type,
                    }
                ]
            }}
            options={{
                resetOnEnd: true,
                autopause: false,
                autoplay: false,
                settings: ['quality', 'speed'],
                speed: { selected: 1, options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2] },
                controls: noControl ? ['play', 'mute', 'volume'] : mainType === "audio" ? ['play', 'progress', 'current-time', 'volume']
                    : className === "norm-chat" ? ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'airplay', 'fullscreen'] :
                        ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen']
            }}
        />

        {mainType === "video" && type !== "youtube" && !noControl ? <button className="zoom-viewer" onClick={() => {
            refPlyr.current?.plyr?.pause();
            openImageAsBig(0);
        }}>
            <ZoomInSvg className="lit" size={20} />
        </button> : null}
    </div>)
}

export default memo(VideoPlayerMain);