class Actions {
    type(type = 'null', x = 0) {
        this[type] = type;
        return {
            type: type,
            peyload: x
        }
    }
}
const actions = new Actions();

export default actions;



