import { useState, useEffect, memo, useCallback, useRef, Suspense } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import QueryString from 'query-string';
import Actions from '../../reducer/actions';
import Axios from '../../main/Axios';
import ScrollBar from '../../main/scrollbar';
import { PropaMeetoor, PuffMeetoor } from '../../main/loadshap/loadshap';
import MainCreatePostArea from '../../components/dashbord/home/createpost/create/createpostarea';
import {
    CloseSvg,
    DeleteSvg,
    MainLogoSvg, MinLogoSvg, PostSvg
} from '../dashbord/icons/icons';
import '../dashbord/dashbord.scss';
import '../dashbord/header/header.scss';
import './style.scss';
import '../../components/dashbord/home/createpost/createpost.scss';
const MainSendAnonyChat = memo(({ fullname, userphoto, username }) => {
    /////////////////////////////////////////////////
    const showEmojiPicker = useSelector(state => state.main.enableEmoji);
    const { messages, badges, buttons, contactus } = useSelector(state => state.sign.langData);
    const dispatch = useDispatch();
    const history = useHistory();
    const maxContent = 220;
    const [miunsMax, setMiunsMax] = useState(maxContent);
    /////////////////////////////////////////////////
    const EditableRef = useRef(null);
    ///////////////////////////////////////////////////
    const checker = useCallback(() => {
        if (EditableRef.current) {
            const length = EditableRef.current.value.trim().length;
            return { isText: length ? true : false, isMax: length > maxContent, length: length };
        }
        return { isText: false, isMax: false };
    }, [EditableRef.current]);
    ///////////////////////////////////////////////////
    const detectMax = useCallback((url) => {
        setMiunsMax(maxContent - url);
    }, [maxContent]);
    /////////////////////////////////////////////////
    const sendAnonyChat = useCallback(async () => {
        try {
            let check = checker();
            if (!check.isText) {
                if (check.isMax) {
                    dispatch(Actions.type("setPopupMini", {
                        html: <>{messages.overLimit}</>,
                        className: "error",
                        classBack: "",
                        close: () => { }
                    }));
                    return;
                }
            }
            await Axios.post("anonyMessage/", {
                username: username,
                message: EditableRef.current.value
            });
            //////////////////////////////////////////
            dispatch(Actions.type("setToast", {
                text: contactus.msg2
            }));
            dispatch(Actions.type("setPopupMain", false));
            history.push("/home");
            setTimeout(() => {
                dispatch(Actions.type("setToast", undefined));
            }, 2000);
        } catch (e) {
            console.log("signin -> catch", e)
        }
    }, [EditableRef.current, checker]);
    //////////////////////////////////////////////////
    return (<div className="create-post">
        <div className="createpost-content">
            <div className="user-info">
                <div className="user-img">
                    <img src={userphoto} alt="user" />
                </div>
                <div className="user-name">
                    <h1 dir="auto">{fullname}</h1>
                    <span className="page-info">
                        {badges.anonyMsg}
                    </span>
                </div>
            </div>
            <div className="outer-more">
                <div className="outer-more-added close">
                    <button className="adder" onClick={() => {
                        if (!checker().isText) {
                            history.push("/home");
                            dispatch(Actions.type("setPopupMain", false));
                            return;
                        }
                        dispatch(Actions.type("setPopupClose", () => {
                            dispatch(Actions.type("setPopupMain", false));
                            history.push("/home");
                        }));
                    }} >
                        <CloseSvg className="red" />
                    </button>
                </div>
            </div>
        </div>
        <div className="content" >
            <ScrollBar>
                <div className="ctm-background">
                    {miunsMax !== maxContent ? <button className="close-backs" onClick={() => {
                        EditableRef.current.value = "";
                        detectMax(0)
                    }} >
                        <DeleteSvg className="red" size="16" />
                    </button> : null}
                    <span className="button-ctm max-char">{miunsMax}</span>
                    <button className="button-ctm post"
                        onClick={sendAnonyChat}>
                        <span className="max-char">
                            <PostSvg className="lit" size="16" /> {buttons.send}
                        </span>
                    </button>
                </div>
                <div className={`outer-text`}>
                    <MainCreatePostArea EditableRef={EditableRef} detectMax={detectMax}
                        showEmojiPicker={showEmojiPicker} maxContent={maxContent} row={7}
                        maxRow={10} background={0} />
                </div>
            </ScrollBar>
        </div>
    </div>)
});
///////////////////////////////////////////////////
const MainOuterAnonyChat = () => {
    const [user, setUser] = useState();
    const dispatch = useDispatch();
    const lang = useSelector(state => state.sign.lang);
    const isDark = useSelector(state => state.sign.isDark);
    const { buttons } = useSelector(state => state.sign.langData);
    const history = useHistory();
    const location = useLocation();
    const { p } = QueryString.parse(location.search);
    //////////////////////////////////////////
    const getProfileInfoApi = async () => {
        try {
            const response = await Axios.get(`publicUser/?username=${p}`);
            if (response.data.erorr) return history.push('/home');
            let res = response.data;
            setUser(true);
            dispatch(Actions.type("setPopupMain", {
                html: <Suspense fallback={<PuffMeetoor size={60}
                    loading={true} clr="drk" rev={true} />}>
                    <MainSendAnonyChat fullname={res?.fullName}
                        userphoto={res?.UserPhoto} username={res?.username} />
                </Suspense>,
                className: ''
            }));
        } catch (e) {
            console.log("Dashbord -> error.data", e)
        }
    }
    /////////////////////////////////////////////
    useEffect(() => {
        getProfileInfoApi();
    }, [p]);
    ////////////////////////////////////////////
    if (user) return (null);
    return (<div className={`main-loader ${isDark ? "DRK" : ""}`}>
        <MainLogoSvg className={`${isDark ? "lit" : "drk"}`} isArabic={lang === "AR"} />
        <PropaMeetoor size={8} loading={true} clr="drk" rev={true} />
        <h1 dir="auto">{buttons.loading}</h1>
        <MinLogoSvg className={`imgback ${isDark ? "lit" : "drk"}`} size={350} />
    </div>);
}

export default memo(MainOuterAnonyChat);