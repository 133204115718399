import { useEffect, lazy, Suspense, memo, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { PuffMeetoor, PropaMeetoor } from '../main/loadshap/loadshap';

import { Helmet } from "react-helmet";
import Actions from '../reducer/actions';
import useDetectPhone from '../main/detectphone';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Profile from '../components/profile/profile';
import PublicPostView from '../components/post/publicpost'
import Anonychat from '../components/anonychat/anony';
import RenderPayPal from './paypal';
import { MiniPopup, ClosePopup, MainPopup, ImagePopup } from './helperApp';
import FollowingSuggest from '../components/dashbord/branches/suggest-followed/suggestfollowed';
import {
  MainLogoSvg, MinLogoSvg
} from '../components/dashbord/icons/icons';
import '../components/dashbord/home/posts/outerposts.scss';
import '../components/dashbord/home/posts/posts.scss';
import '../components/dashbord/home/posts/postloader.scss';
import './scrollbar.scss'
import './App.scss';
/////////////////////////////////////////////////
const Sign = lazy(() => import('../components/sign/sign'));
const Home = lazy(() => import('../components/home/home'));
const Contact = lazy(() => import('../components/contact/contact'));
const Dashbord = lazy(() => import('../components/dashbord/dashbord'));
const Logout = lazy(() => import('../components/sign/logout'));
const Policy = lazy(() => import('../components/policy/policy'));
const AppDownload = lazy(() => import('./appdownload'));
//////////////////////////////////////////////////
export const MainLoader = memo(() => {
  const lang = useSelector(state => state.sign.lang);
  const isDark = useSelector(state => state.sign.isDark);
  ////////////////////////////////////////////////
  return (<div className={`main-loader ${isDark ? "DRK" : ""}`}>
    <MainLogoSvg className={`${isDark ? "lit" : "drk"}`} isArabic={lang === "AR"} />
    <PropaMeetoor size={8} loading={true} clr="drk" rev={true} />
    <h1>meetoor.com</h1>
    <MinLogoSvg className={`imgback ${isDark ? "lit" : "drk"}`} size={350} />
  </div>)
});
//////////////////////////////////////////////////
export const TempLoader = memo(() => (<div className="temp-inner-loader">
  <PuffMeetoor size={60} loading={true} />
</div>));
//////////////////////////////////////////////////
const MainMeetoorApp = () => {
  const { detect, platform } = useDetectPhone();
  const title = useSelector(state => state.main.title);
  const filter = useSelector(state => state.main.filter);
  const message = useSelector(state => state.main.message);
  const Store = useSelector(state => state.main.store);
  const popupMainWindow = useSelector(state => state.main.popupMainWindow);
  const isApp = useSelector(state => state.sign.isApp);
  const sendMessage = useSelector(state => state.sign.sendMessage);
  const isDark = useSelector(state => state.sign.isDark);
  const { viewData, buttons, messages } = useSelector(state => state.sign.langData);
  const dispatch = useDispatch();
  ///////////////////////////////////////
  useEffect(() => {
    document.title = title || "Meetoor";
  }, [title]);
  ///////////////////////////////////////
  let appClass = "App";
  let loading = null;
  useMemo(() => {
    if (filter) {
      appClass = "App filter";
      loading = <div className="set-filter">
        <PuffMeetoor size={60} loading={true} />
        <div className="message" dir="auto">
          {message}
        </div>
      </div>;
    }
  }, [filter, message]);
  ///////////////////////////////////////////
  const downloadInfo = useMemo(() => {
    return {
      ...viewData.downloadInfo,
      button: buttons.continue
    }
  }, []);
  ////////////////////////////////////////////
  useEffect(() => {
    const HandleBack = () => {
      let popups = []
      for (let key in Store?.popup) {
        if (Store?.popup?.hasOwnProperty(key)) popups.push(Store?.popup[key]);
      }
      if (popups.length) {
        dispatch(Actions.type(popups[popups.length - 1], false));
        return true;
      } else {
        return false;
      }
    };
    /////////////////////////////////////////
    const callFromWebView = (event) => {
      if (typeof event.data !== "string") return;
      const data = typeof event.data === "string" ? JSON.parse(event.data) : undefined;
      if (!data) return;
      const type = data.type;
      if (!type) return;
      switch (type) {
        case "wvapp":
          dispatch(Actions.type("setIsApp", true));
          dispatch(Actions.type("setVersionApp", data.version));
          sendMessage({ mode: isDark, type: "mode" });
          break;

        case "suggestUsers":
          dispatch(Actions.type("setPopupMain", {
            html: <FollowingSuggest />, className: ''
          }));
          break;

        case "goUrl":
          dispatch(Actions.type("setGoURL", data.url));
          break;

        case "goback":
          let back = HandleBack();
          if (!back) sendMessage({ type: "goback" });
          break;

        case "stop-foreground":
          if (data.stop === "end-call") {
            dispatch(Actions.type("setRunCall", false));
          } else if (data.stop === "end-room") {
            dispatch(Actions.type("setAudioRoom", false));
          }
          break;

        default:
          break;
      }
    }

    document.addEventListener("message", callFromWebView);
    return () => {
      document.removeEventListener("message", callFromWebView);
    }
  }, [])
  ////////////////////////////////////////////
  return (<Router>
    <div className={appClass} >
      <Switch>
        <Route path="/meetoor" render={(e) => {
          return (<Suspense fallback={<MainLoader />}>
            <Helmet>
              <link rel="canonical" href={`/home/meetoor/${e.location.search}`} />
              <meta property="og:type" content="website" />
              <meta property="og:url" content={`/home/meetoor/${e.location.search}`} />
              <meta property="og:title" content={messages.roomDescription} />
              <meta property="og:site_name" content="Meetoor" />
              <meta property="og:description" content={messages.roomDescription} />
            </Helmet>
            <Redirect from='/meetoor' to={`/home/meetoor/${e.location.search}`} />
          </Suspense>)
        }} />
        <Route path="/profile" render={() => (<Suspense fallback={<MainLoader />}>
          <Helmet>
            <title>حساب شخصي على ميتور</title>
            <meta name="description" content="هذا حساب شخصي يملكه هذا الشخص على منصة ميتور" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`/home/profile/`} />
            <meta property="og:title" content="حساب شخصي على ميتور" />
            <meta property="og:image" content="https://cdn.meetoor.com/media/media/UsersPhoto/Meetoor_edxgilzqhjudjhirtugj.jpeg" />
            <meta property="og:site_name" content="Meetoor" />
            <meta property="og:description" content="يتضمن هذا الوصف للشخص الحالي" />
          </Helmet>
          <Profile />
        </Suspense>)} />
        <Route path="/writeanonychat" render={() => (<Suspense fallback={<MainLoader />}>
          <Anonychat />
        </Suspense>)} />
        <Route path="/signin" render={() => (<Suspense fallback={<MainLoader />}>
          <Sign />
        </Suspense>)} />
        <Route path="/signup" render={() => (<Suspense fallback={<MainLoader />}>
          <Sign />
        </Suspense>)} />
        <Route path="/logout" render={() => (<Suspense fallback={<MainLoader />}>
          <Logout />
        </Suspense>)} />
        <Route path="/forget_password" render={() => (<Suspense fallback={<MainLoader />}>
          <Sign />
        </Suspense>)} />
        <Route path="/reset_password" render={() => (<Suspense fallback={<MainLoader />}>
          <Sign />
        </Suspense>)} />
        <Route path="/paypal" render={() => (<Suspense fallback={<MainLoader />}>
          <RenderPayPal />
        </Suspense>)} />
        <Route  path="/home" render={() => (<Suspense fallback={<MainLoader />}>
          <Dashbord />
        </Suspense>)} />
        <Route path="/postview" render={() => (<Suspense fallback={<MainLoader />}>
          <Helmet>
            <title>منشور | ميتور</title>
            <meta name="description" content="منشور على منصة ميتور" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={`https://meetoor.com/home#postview_id`} />
            <meta property="og:title" content="منشور | ميتور" />
            <meta property="og:image" content="https://cdn.meetoor.com/media/media/UsersPhoto/Meetoor_edxgilzqhjudjhirtugj.jpeg" />
            <meta property="og:site_name" content="Meetoor" />
            <meta property="og:description" content="منشور على منصة ميتور" />
          </Helmet>
          <PublicPostView />
        </Suspense>)} />
        <Route exact path="/policy" render={() => (<div className="meetoor-home-main" >
            <Suspense fallback={<PuffMeetoor size={40} loading={true} />}><Header /></Suspense>
            <Suspense fallback={<TempLoader />}><Policy /></Suspense>
            <Suspense fallback={<PuffMeetoor size={40} loading={true} />}><Footer /></Suspense>
        </div>)} />
        <Route exact path="/contact_us" render={() => (<div className="meetoor-home-main" >
            <Suspense fallback={<PuffMeetoor size={40} loading={true} />}><Header /></Suspense>
            <Suspense fallback={<TempLoader />}><Contact /></Suspense>
            <Suspense fallback={<PuffMeetoor size={40} loading={true} />}><Footer /></Suspense>
        </div>)} />
        <Route exact path="/" render={() => (<div className="meetoor-home-main" >
          {(detect && (platform === "tablet" || platform === "phone")) ?
            <>
              <Redirect from='/' to='/' />
              <Suspense fallback={<MainLoader />}>
                {isApp ? <Redirect from='/' to='/signup' /> : <AppDownload {...downloadInfo} />}
              </Suspense>
            </> :
            <>
            <Suspense fallback={<PuffMeetoor size={40} loading={true} />}><Header /></Suspense>
            <Suspense fallback={<TempLoader />}><Home /></Suspense>
            <Suspense fallback={<PuffMeetoor size={40} loading={true} />}><Footer /></Suspense>
            </>}
        </div>)} />
        <Redirect from='*' to='/home' />
      </Switch>
      {popupMainWindow && <MainPopup />}
      <ClosePopup />
      <ImagePopup />
      <MiniPopup />
    </div>
    {loading}
  </Router>);
}
export default memo(MainMeetoorApp);