import { useState, useEffect, Fragment } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useDetectPhone from '../../main/detectphone';
import QueryString from 'query-string';
import Actions from '../../reducer/actions';
import Axios from '../../main/Axios';
import ScrollBar from '../../main/scrollbar';
import { PropaMeetoor } from '../../main/loadshap/loadshap';
import {
    MainLogoSvg, MinLogoSvg, SecureSvg,
    AddressSvg, BirthdaySvg, CountrySvg,
    GanderSvg, JobSvg, SkillSvg, TeamsSvg
} from '../dashbord/icons/icons';
import '../dashbord/dashbord.scss';
import '../dashbord/header/header.scss';
import './style.scss';
///////////////////////////////////////////////////
const About = ({ infos }) => {
    const { badges } = useSelector(state => state.sign.langData);
    /////////////////////////////////////////
    const imgByKey = {
        "job": <JobSvg className="drk" size={25} />,
        "skill": <SkillSvg className="drk" size={25} />,
        "followers": <TeamsSvg className="drk" size={25} />,
        "Address": <AddressSvg className="drk" size={25} />,
        "country": <CountrySvg className="drk" size={25} />,
        "gender": <GanderSvg className="drk" size={25} />,
        "birthday": <BirthdaySvg className="drk" size={25} />,
    }
    //////////////////////////////////////////
    return (<div className="outer-info">
        {Object.keys(imgByKey).map((key, id) => {
            let img = imgByKey[key];
            let info = infos[key];
            if (!info) return null;
            if (key === 'gender') return (<div key={key + id} className="info">
                {img}
                <span>{info ? "male" : "female"}</span>
            </div>)
            if (key === 'followers') return (<div key={key + id} className="info">
                {img}
                <span>{info} {badges.followers}</span>
            </div>)
            if (key === 'birthday') return (<div key={key + id} className="info">
                {img}
                <span>{badges.bornIn} {info}</span>
            </div>)

            return (<div key={key + id} className="info">
                {img}
                <span>{info}</span>
            </div>)
        })}
    </div>)
}
///////////////////////////////////////////////////
const MainOuterProfile = () => {
    const [render, setRender] = useState(false);
    const [user, setUser] = useState();
    const lang = useSelector(state => state.sign.lang);
    const cdnUrl = useSelector(state => state.main.cdnUrl);
    const loggedIn = useSelector(state => state.sign.loggedIn);
    const isDark = useSelector(state => state.sign.isDark);
    const { buttons, meetoorOwner } = useSelector(state => state.sign.langData);
    const history = useHistory();
    const { currnetWidth } = useDetectPhone();
    const dispatch = useDispatch();
    const location = useLocation();
    const { p } = QueryString.parse(location.search);
    //////////////////////////////////////////
    const openImageAsBig = (urlImg) => {
        dispatch(Actions.type("setPopupImage", {
            className: 'sml',
            index: 0,
            urls: [urlImg],
            avatar: true
        }));
    }
    ///////////////////////////////////////////////
    useEffect(() => {
        if (loggedIn) {
            history.push(`/home/profile/?p=${p}`);
        } else {
            setRender(true);
        }
    }, [])
    /////////////////////////////////////////////
    const getProfileInfoApi = async () => {
        try {
            const response = await Axios.get(`publicUser/?username=${p}`);
            if (response.data.erorr) return history.push('/home');
            setUser(response.data)
        } catch (e) {
            console.log("Dashbord -> error.data", e)
        }
    }
    /////////////////////////////////////////////
    useEffect(() => {
        getProfileInfoApi();
    }, [p]);
    ////////////////////////////////////////////
    if (user && render) return (<div className="meetoor-dashbord meetoor">
        <div className="dashbord-outer-header">
            {currnetWidth >= 770 && <div className="dashbord-tabs-logo">
                <Link className="logo" to={`/home`}>
                    <MainLogoSvg className={`${isDark ? "lit" : "drk"}`} isArabic={lang === "AR"} />
                </Link>
            </div>}
            <div className="dashbord-outer-inner">
                <div className="dashbord-inner-header">

                    {currnetWidth <= 770 && <>
                        <Link className="logo min" to={`/home`}>
                            <MinLogoSvg className={isDark ? "lit" : "drk"} />
                        </Link>
                    </>}

                    <div className="live-meetoor-fromout">
                        <button className="header user" >
                            <Link className="nav-link signin" to="/signin">
                                {buttons.signin}
                            </Link>
                        </button>
                        <button className="header user" >
                            <Link className="nav-link signup" to="/signup">
                                {buttons.signup}
                            </Link>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div className="dashbord-fixed-home" style={{ width: "100%" }}>
            <div className="continar-bottom">
                <div className="window-changed profile">
                    <ScrollBar >
                        <div className="usersection">
                            <div className="frontsection">
                                {user.isOwner ? <div className="infosection-sml top">
                                    <div className="info">
                                        {meetoorOwner}
                                    </div>
                                </div> : null}
                                <div className="frontimg">
                                    {user.isOwner ? <img className="owner-badge" src={process.env.PUBLIC_URL + "/img/owner.png"} alt="owner" /> : null}

                                    <img src={user.UserPhoto} alt="user-img"
                                        onClick={() => openImageAsBig(user.UserPhoto)}
                                        className="img" />

                                    <div className={`badge ${user.isOwner ? "owner" : ""}`}>
                                        {user.isOwner ? <img className="owner-badge" src={process.env.PUBLIC_URL + "/img/owner.png"} alt="owner" /> : null}

                                        <span className="inner">
                                            <img src={cdnUrl + user.sticker} alt="sticker" />
                                        </span>
                                    </div>
                                </div>
                                <div className="infosection-sml">
                                    <div className="info" >
                                        {user.isSecure ? <SecureSvg className="drk" size={18} /> : null}
                                        <h1 dir="auto">{user.fullName}</h1>
                                    </div>

                                    <span className="info sml">
                                        <h1 dir="auto">{user.infos.job}</h1>
                                    </span>
                                </div>
                            </div>


                        </div>

                        <div className="aboutsection">
                            <About infos={user.infos} />
                        </div>
                    </ScrollBar>
                </div>
            </div>
        </div>
    </div>)
    return (<div className={`main-loader ${isDark ? "DRK" : ""}`}>
        <MainLogoSvg className={`${isDark ? "lit" : "drk"}`} isArabic={lang === "AR"} />
        <PropaMeetoor size={8} loading={true} clr="drk" rev={true} />
        <h1 dir="auto">{buttons.loading}</h1>
        <MinLogoSvg className={`imgback ${isDark ? "lit" : "drk"}`} size={350} />
    </div>);
}

export default MainOuterProfile;